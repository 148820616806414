[
    {
        "id": 3,
        "label": "Gates Of Olympus 1000",
        "image": "https://images.ctfassets.net/3h2k9ldv19d8/25vAW3dOzCO2v6iiH2K9q3/a125f5ff8fef61c19fc2c659d69afdb9/3.Square-.png?fm=webp&w=3840&q=75",
        "provider": {
            "label": "Pragmatic Play",
            "id": "pragmatic-play"
        },
        "path": "gates-of-olympus"
    },
    {
        "id": 4,
        "label": "Starlight Princes 1000",
        "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20starlightx.png",
        "provider": {
            "label": "Pragmatic Play",
            "id": "pragmatic-play"
        },
        "path": "starlight-princes"
    },
    {
        "id": 5,
        "label": "Sweet Bonanza 1000",
        "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20fruitswx.png?v=2",
        "provider": {
            "label": "Pragmatic Play",
            "id": "pragmatic-play"
        },
        "path": "sweet-bonanza-1000"
    },
    {
        "id": 6,
        "label": "Pyramid Bonanza",
        "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20pbonanza.png",
        "provider": {
            "label": "Pragmatic Play",
            "id": "pragmatic-play"
        },
        "path": "pyramid-bonanza"
    },
    {
        "id": 7,
        "label": "Mahjong Ways 2",
        "image": "https://files.sitestatic.net/SlotImages/pgsoft/mahjong-ways2.png",
        "provider": {
            "label": "PG Soft",
            "id": "pg-soft"
        },
        "path": "mahjong-ways-2"
    },
    {
        "id": 8,
        "label": "Mahjong Ways",
        "image": "https://files.sitestatic.net/SlotImages/pgsoft/mahjong-ways2.png",
        "provider": {
            "label": "PG Soft",
            "id": "pg-soft"
        },
        "path": "mahjong-ways"
    },
    {
        "id": 9,
        "label": "Lucky Neko",
        "image": "https://files.sitestatic.net/SlotImages/pgsoft/luckyneko_.png",
        "provider": {
            "label": "PG Soft",
            "id": "pg-soft"
        },
        "path": "lucky-neko"
    }
]